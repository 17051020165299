@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.eighthScreenContainer {
  width: 100%;
  height: size-rem(1000px);
  overflow: hidden;
  position: relative;
  @include vp--740 {
    height: size-rem(992px, true);
  }
}

.eighthScreenWrapper {
  width: 100%;
  overflow: hidden;
  @include flex-row;
  height: 100%;
  @include vp--740 {
    height: size-rem(1030px, true);
    @include flex-column;
    background-color: $clr-green;
  }
}

.eighthScreenTitle {
  position: absolute;
  top: size-rem(160px);
  left: size-rem(80px);
  z-index: 10;
  pointer-events: none;
  transform: translate3d(0, 0, 10px);
  @include vp--740 {
    top: size-rem(60px, true);
    left: 5%;
    width: size-rem(365px, true);
  }
}
.eighthScreenTitle p {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 95%;
  letter-spacing: -0.02em;
  color: $clr-white;
  margin: 0;
  z-index: 10;
  @include vp--740 {
    font-size: size-rem(40px, true);
  }
}

.eighthScreenTitle :last-child {
  margin-left: size-rem(60px);
  @include vp--740 {
    margin-left: size-rem(0px);
  }
}

.eighthScreenBoxDescription {
  width: 50%;
  background-color: $clr-green;
  @include flex-column;
  @include vp--740 {
    width: 100%;
    height: size-rem(445px, true);
  }
}

.eighthScreenSubTitle {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(40px);
  line-height: 110%;
  letter-spacing: -0.02em;
  color: $clr-white;
  margin-left: size-rem(82px);
  margin-top: size-rem(516px);
  margin-bottom: size-rem(30px);
  z-index: 3;
  @include vp--740 {
    margin-left: 5%;
    margin-top: size-rem(218px, true);
    margin-bottom: size-rem(20px, true);
    font-size: size-rem(22px, true);
  }
}

.eighthScreenDescription {
  width: size-rem(420px);
  margin-top: size-rem(30px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $clr-white;
  margin-left: size-rem(82px);
  @include vp--740 {
    margin-left: 5%;
    width: 85%;
    font-size: size-rem(14px, true);
    z-index: 3;
    line-height: 150%;
  }
}

.eighthScreenImageBox {
  width: 50%;
  @include vp--740 {
    width: 100%;
    height: size-rem(531px, true);
  }
}

.eighthScreenImageBox div img {
  max-width: 110%;
}

.eighthScreenSvg {
  position: absolute;
  width: size-rem(1352px);
  height: size-rem(731px);
  left: size-rem(-114px);
  top: size-rem(352px);
  z-index: 2;
  @include vp--740 {
    width: size-rem(421px, true);
    height: size-rem(294px, true);
    left: size-rem(-94px);
    top: size-rem(-150px);
    transform: rotate(5deg);
    z-index: 1;
  }
  @include vp--tablet {
    left: size-rem(-504px);
    top: size-rem(282px);
  }
}
.buttonContainer {
  position: absolute;
  @include flex-row_sb;
  left: 50%;
  width: 50%;
  z-index: 10;
  height: 100%;
}
.buttonWrapperPrev,
.buttonWrapperNext {
  cursor: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.swiperButtonNext,
.swiperButtonPrev {
  width: size-rem(160px);
  height: size-rem(160px);
  position: absolute;
  border: none;
  background: none;
  pointer-events: none;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiperButtonIconPrev {
  width: size-rem(160px);
  height: size-rem(160px);
  rotate: 180deg;
}

.swiperButtonIconNext {
  width: size-rem(160px);
  height: size-rem(160px);
}

.swiperSlideImg {
  height: 100%;
  & picture img {
    height: size-rem(1000px);
    @include vp--740 {
      height: size-rem(485px, true);
    }
  }
}

.swiperPaginationDesktop {
  position: absolute;
  bottom: size-rem(60px);
  left: 45%;
  z-index: 100;
  color: $clr-white;
  font-size: size-rem(32px);
}

.buttonContainerMobile {
  @include flex-row_sb;
  width: size-rem(210px);
  height: size-rem(70px);
  margin-left: 5%;
  margin-bottom: size-rem(20px);
  align-items: center;
  z-index: 3;
  position: absolute;
  bottom: size-rem(20px);
  @include vp--740 {
    position: initial;
    bottom: initial;
    @include flex-row_sb;
    width: size-rem(140px, true);
    height: size-rem(50px, true);
    margin-left: 7%;
    margin-bottom: size-rem(20px, true);
    align-items: center;
    z-index: 3;
  }
}

.swiperButtonNextMobile2 {
  width: size-rem(60px);
  height: size-rem(60px);
  background: none;
  border-radius: 100%;
  border: 1px solid $clr-white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include vp--740 {
    width: size-rem(40px, true);
    height: size-rem(40px, true);
    background: none;
    border-radius: 100%;
    border: 1px solid $clr-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.swiperButtonIconNextMobile {
  width: size-rem(20px);
  @include vp--740 {
    width: size-rem(12px, true);
  }
}

.swiperPaginationMobile {
  color: $clr-white;
  font-size: size-rem(18px);
  @include vp--740 {
    font-size: size-rem(14px, true);
  }
}

.swiperButtonPrevMobile {
  width: size-rem(60px);
  height: size-rem(60px);
  background: none;
  border-radius: 100%;
  border: 1px solid $clr-white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include vp--740 {
    width: size-rem(40px, true);
    height: size-rem(40px, true);
    background: none;
    border-radius: 100%;
    border: 1px solid $clr-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.swiperButtonIconPrevMobile {
  width: size-rem(20px);
  rotate: 180deg;
  @include vp--740 {
    width: size-rem(12px, true);
    rotate: 180deg;
  }
}
